import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";

const OrderInfoTable = () => {
  const { id } = useParams(); // Get order ID from URL
  const location = useLocation(); // Access state passed via Link
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.albazaarkorea.com/admin/order/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrder(response.data);
      } catch (error) {
        console.error("Failed to fetch order details:", error);
      }
    };

    fetchOrderDetails();
  }, [id]);

  if (!order) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="bg-white p-8">
      <h2 className="font-bold text-2xl mb-4">Order Details</h2>
      <div className="bg-white p-4 rounded-md">
        <table className="min-w-full leading-normal rounded-md">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Field
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Order ID
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.order_id}
              </td>
            </tr>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Name
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.receiver_name}
              </td>
            </tr>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Phone
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.user_id}
              </td>
            </tr>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Address
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.address_data?.type === "text"
                  ? order.address_data.address
                  : "Get address from image"}
              </td>
            </tr>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Orders
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.cart
                  .map(
                    (product) =>
                      `${product.product_name} (${product.weight} x ${product.quantity})`
                  )
                  .join(", ")}
              </td>
            </tr>
            <tr>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                Status
              </td>
              <td className="px-5 py-5 border-b border-gray-200 text-sm">
                {order.status}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderInfoTable;
