import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

const OrderPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = localStorage.getItem("token");

  // States
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  // Search states
  const [searchOrderId, setSearchOrderId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const limit = 50;
  const page = parseInt(searchParams.get("page") || "1", 10);

  const fetchOrders = async (currentPage) => {
    try {
      setLoading(true);
      // Build search query parameters
      const searchQuery = new URLSearchParams({
        page: currentPage,
        limit,
        ...(searchOrderId && { order_id: searchOrderId }),
        ...(searchName && { name: searchName }),
        ...(searchPhone && { phone: searchPhone }),
      });

      const response = await axios.get(
        `https://api.albazaarkorea.com/admin/orders?${searchQuery.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(response.data.orders);
      setFilteredOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(page);
  }, [page]);

  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
    setSearchParams({ page: 1 }); // Reset to first page when searching
    fetchOrders(1);
  };

  // Clear search
  const handleClearSearch = async () => {
    // First clear all search states
    setSearchOrderId("");
    setSearchName("");
    setSearchPhone("");
    setIsSearching(false);

    try {
      setLoading(true);
      // Reset search params and fetch all orders
      setSearchParams({ page: 1 });

      const response = await axios.get(
        `https://api.albazaarkorea.com/admin/orders?page=1&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOrders(response.data.orders);
      setFilteredOrders(response.data.orders);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch orders on page change if we're not searching
    if (!isSearching) {
      fetchOrders(page);
    }
  }, [page, isSearching]); // Add isSearching to dependencies

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setSearchParams({ page: newPage });
    }
  };

  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleExportToExcel = () => {
    const dataToExport = filteredOrders
      .filter((order) => selectedOrders.includes(order._id))
      .map((order) => ({
        "Order ID": order.order_id,
        Phone: order.user_id,
        Name: order.receiver_name,
        Address:
          order.address_data?.type === "text"
            ? order.address_data.address
            : "Get address from image",
        Orders: order.cart
          .map(
            (product) =>
              `${product.product_name} (${product.weight} x ${product.quantity})`
          )
          .join(", "),
        Sender: "Albazaar",
        Notes: order.shippingReq,
      }));

    if (dataToExport.length === 0) {
      alert("No data selected for export!");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    const currentDate = new Date().toISOString().split("T")[0];
    XLSX.writeFile(workbook, `OrderInfo_${currentDate}.xlsx`);
    setSelectedOrders([]);
  };

  const handleBatchStatusChange = async () => {
    if (!selectedStatus) {
      alert("Please select a status to apply.");
      return;
    }

    if (selectedOrders.length === 0) {
      alert("No orders selected.");
      return;
    }

    try {
      await axios.put(
        `https://api.albazaarkorea.com/admin/orders/batch-status`,
        { ids: selectedOrders, status: selectedStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Status updated successfully.");
      setSelectedOrders([]);
      fetchOrders(page);
    } catch (error) {
      console.error("Failed to update order statuses:", error);
      alert("An error occurred while updating statuses.");
    }
  };

  if (loading) {
    return (
      <div className="flex ml-64 w-full items-center justify-center h-screen">
        <div className="text-center">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-2 text-lg font-semibold">Loading orders...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white ml-64 p-8 w-full">
      <h2 className="font-bold text-2xl mb-4">Order Page</h2>

      <form onSubmit={handleSearch} className="mb-6 bg-gray-50 p-4 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <input
              type="text"
              placeholder="Search by Order ID"
              value={searchOrderId}
              onChange={(e) => setSearchOrderId(e.target.value)}
              className="w-full px-4 py-2 border rounded"
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Search by Name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="w-full px-4 py-2 border rounded"
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Search by Phone"
              value={searchPhone}
              onChange={(e) => setSearchPhone(e.target.value)}
              className="w-full px-4 py-2 border rounded"
            />
          </div>
          <div className="flex gap-2">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
            >
              Search
            </button>
            {isSearching && (
              <button
                type="button"
                onClick={handleClearSearch}
                className="px-4 py-2 bg-gray-500 text-white rounded shadow hover:bg-gray-600"
              >
                Clear
              </button>
            )}
          </div>
        </div>
      </form>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="px-4 py-2 border rounded mr-2"
          >
            <option value="">Select Status</option>
            <option value="On Hold">On Hold</option>
            <option value="Processing">Processing</option>
            <option value="Confirmed">Confirmed</option>
            <option value="Delivered">Delivered</option>
            <option value="Canceled">Canceled</option>
          </select>
          <button
            onClick={handleBatchStatusChange}
            className="px-4 py-2 bg-green-500 text-white rounded shadow"
          >
            Apply Status
          </button>
        </div>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded shadow"
          onClick={handleExportToExcel}
          disabled={selectedOrders.length === 0}
        >
          Export Selected to Excel
        </button>
      </div>
      <div className="bg-white p-4 rounded-md">
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal rounded-md">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Select
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Order ID
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Phone
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Total
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Payment
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr
                  key={index}
                  className={`${
                    order.status === "On Hold"
                      ? "bg-white"
                      : order.status === "Processing"
                      ? "bg-gray-100"
                      : order.status === "Confirmed"
                      ? "bg-gray-200"
                      : order.status === "Delivered"
                      ? "bg-green-100"
                      : order.status === "Canceled"
                      ? "bg-red-50"
                      : ""
                  }`}
                >
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <input
                      type="checkbox"
                      checked={selectedOrders.includes(order._id)}
                      onChange={() => handleCheckboxChange(order._id)}
                    />
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <Link
                      to={`/order/${order._id}`}
                      state={{ page, selectedOrders }}
                      className="text-blue-500 hover:underline"
                    >
                      {order.order_id}
                    </Link>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    {order.date}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    {order.receiver_name}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    {order.user_id}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    {order.total}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    {order.payment_type}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <div
                      className={`flex items-center justify-center p-2 rounded-md text-white ${
                        order.status === "On Hold"
                          ? "bg-gray-500"
                          : order.status === "Processing"
                          ? "bg-yellow-500"
                          : order.status === "Confirmed"
                          ? "bg-blue-500"
                          : order.status === "Delivered"
                          ? "bg-green-500"
                          : order.status === "Canceled"
                          ? "bg-red-500"
                          : "bg-gray-300"
                      }`}
                    >
                      {order.status}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded shadow"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <p>
            Page {page} of {totalPages}
          </p>
          <button
            className="px-4 py-2 bg-gray-300 rounded shadow"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
